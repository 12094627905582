<template>
  <div class="item-box">
    <div class="h1">准入工厂格局</div>
    <!--表格部分-->
    <div class="header">
      <div>准入国</div>
      <div>输华公司数</div>
      <div>输华工厂数</div>
      <div>暂停工厂数</div>
      <div>输华占比 <br/>({{ Ratio1Name }})</div>
      <div>输华占比 <br/>({{ Ratio2Name }})</div>
      <div>关税 | 增值税</div>
    </div>
    <div class="table-box">
      <div class="table-body">
        <!-- 外层节点 -->
        <div v-for="(item, index) in list" :key="index" class="item-box">
          <div class="father-item">
            <div class="item">{{ item.Country }}</div>
            <div class="item">
              <div class="progress-style">
                <p>{{ item.CompanyNum }}</p>
                <b-progress :value="item.CompanyNum*2" :max="100" class="item" variant="primary" height="10px"/>
              </div>
            </div>
            <div class="item">
              <div class="progress-style">
                <p>{{ item.NormalPlantNum }}</p>
                <b-progress :value="item.NormalPlantNum*2" :max="100" class="item" variant="success" height="10px"/>
              </div>
            </div>
            <div class="item">
              <div class="progress-style">
                <p>{{ item.StopPlantNum }}</p>
                <b-progress :value="item.StopPlantNum*2" :max="100" class="item" variant="danger" height="10px"/>
              </div>
            </div>
            <div class="item">{{ showStar ? '******' : toPercent(item.Ratio1, 2) }}</div>
            <div class="item">{{ showStar ? '******' : toPercent(item.Ratio2, 2) }}</div>
            <div class="item">
              <div style="display: flex; align-items: center; justify-content: center;">
                <p style="width: 50px; margin: 0;">{{ toPercent(item.Tariff, 1) }}</p>
                <p style="width: 50px; margin: 0;">{{ toPercent(item.Vat, 0) }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="list.length === 0" style="width: 100%; height: 500px; display: flex; flex-direction: column; justify-content: center; align-items: center; font-size: 18px;">
          <img style="width: 198px;height: 211px" src="@/assets/image/cn/nodata.png">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLocalStore } from '@/utils/common'

export default {
  name: 'CnBTCEstApprovedPlant',
  components: {
  },
  data () {
    return {
      list: [],
      isFirstView: true,
      indexQuery: {
        PageNum: 1,
        PageSize: 10,
        KeyWords: ''
      },
      Ratio1Name: '',
      Ratio2Name: ''
    }
  },
  computed: {
    showStar () {
      // return !this.$store.getters.token || getLocalStore().ChargeStataEn === 2
      return false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    addSearchInfo () {
      this.$store.dispatch('AddWebPlantSearchInfo', {
        UserID: getLocalStore().UserID,
        SearchCategory: 'Outline of Approved Beef Establishments',
        SearchSection: this.currentSection,
        SearchContent: this.searchContent
      })
    },
    getList () {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('GetWebFrontApprovePlantPattern', {
        KeyWords: this.indexQuery.KeyWords,
        LanguageCode: 'zh-CN',
        UserID: getLocalStore().UserID,
        IsFirst: this.isFirstView
      }).then(() => {
        this.list = this.$store.getters.etcPlantPatterns
        this.Ratio1Name = this.$store.getters.etcPlantPatterns[0].Ratio1Name
        this.Ratio2Name = this.$store.getters.etcPlantPatterns[0].Ratio2Name
        this.$store.dispatch('SetLoadingStatus', false)
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    handleChange (item) {
    },
    toPercent (point, num) {
      let percent = Number(point * 100).toFixed(num)
      percent += '%'
      return percent
    }
  }
}
</script>

<style lang="scss" scoped>

$cell-width: 171px;
$cell-height: 50px;

.item-box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h1{
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0 10px 0;
}

// 表头
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  height: $cell-height;
  border-radius: 8px;
  background: #e6e6e6;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
}

.header > div {
  min-width: $cell-width;
  word-break: break-all;
  text-align: center;
}

::-webkit-scrollbar {
  display:none
}

.table-body {
  margin-top: 10px;
  height: 1000px;
  min-width: 1200px;
  overflow-y: scroll;
}

.table-body > .item-box {
  min-height: $cell-height;
}

.table-body > .item-box > .father-item {
  height: $cell-height;
  border-radius: 7px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
  box-shadow: 0 1px 10px 1px #e1e1e1;
}
.table-body > .item-box > .father-item > .item {
  width: $cell-width;
  height: $cell-height;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
}

.progress-style {
  display: flex;
  align-items: center;
  justify-content: center;
}
.progress-style > p {
  width: 25px;
  margin: 0;
}
.progress-style > .item {
  width: 117px;
  margin-left: 5px;
  border-radius: 10px!important;
}

</style>
